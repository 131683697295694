<template>
  <footer class="site-footer">
    <div class="text-right">
      {{ $t('general.powered_by') }} {{ $t('general.copyright') }}
    </div>
  </footer>
</template>

<script type="text/babel">
export default {
  data() {
    return {
      footer: 'footer',
    };
  },
};
</script>

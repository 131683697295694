import { render, staticRenderFns } from "./View.vue?vue&type=template&id=72e146ab&"
import script from "./View.vue?vue&type=script&lang=js&"
export * from "./View.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\laragon\\www\\invoiceasy\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72e146ab')) {
      api.createRecord('72e146ab', component.options)
    } else {
      api.reload('72e146ab', component.options)
    }
    module.hot.accept("./View.vue?vue&type=template&id=72e146ab&", function () {
      api.rerender('72e146ab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/invoices/View.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "site-footer" }, [
    _c("div", { staticClass: "text-right" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$t("general.powered_by")) +
          " " +
          _vm._s(_vm.$t("general.copyright")) +
          "\n  "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }